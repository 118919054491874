export const environment = {
  production: true,
  AD_UNIT_ID:'12345',
  IS_TESTING:true,
  firebaseConfig: {
    apiKey: "AIzaSyAs6nWu6ABqt8KJL6WFvt9_zBB4AJGN5Ec",
    authDomain: "gkquestions-75679.firebaseapp.com",
    databaseURL: "https://gkquestions-75679-default-rtdb.firebaseio.com/",
    projectId: "gkquestions-75679",
    storageBucket: "gkquestions-75679.appspot.com",
    messagingSenderId: "579555089712",
    appId: "1:579555089712:web:d64760cd9df16319e8fcf5",
    measurementId: "G-653K8WLCJM"
  },
  defaultAnswerCount: 4, //Answer input count,
  snackBarConfig: {
    duration: 2 * 1000
  },
  ONESIGNAL_APP_ID: "e2c0bb4d-1501-4cd1-a5d5-a60ae6f1ac9f",
  ONESIGNAL_REST_API_KEY: "ZDkxMGNlMzktYjc1Ny00YTU1LTg1YjgtNDNkNGE1MTEyMDA0"
};
